<template>
    <a-drawer width="50%" title="转账详情" placement="right" :maskClosable="false" :visible="MakeMoneyInfoDrawerVisible" @close="onClose">
        <a-descriptions>
            <a-descriptions-item label="转账金额">
                {{ info.money }}
            </a-descriptions-item>
            <a-descriptions-item label="提交时间">
                {{ moment(info.addTime) }}
            </a-descriptions-item>
            <a-descriptions-item label="审核状态">
                {{ info.auditingState == 0 ? "未审核" : info.auditingState == 1 ? "已通过" : info.auditingState == 2 ? "已拒绝" : "" }}
            </a-descriptions-item>
            <a-descriptions-item label="转账凭证" :span="3">
                <img @click="preview(info.fileUrl)" class="img" :src="resourcesHost + info.fileUrl" />
            </a-descriptions-item>
            <a-descriptions-item label="审核说明" :span="3">
                {{ info.auditingExplain || "" }}
            </a-descriptions-item>
        </a-descriptions>
        <Vviewer ref="viewer"></Vviewer>
    </a-drawer>
</template>
<script>
import Vviewer from "../../../components/Vviewer.vue";
export default {
    components: { Vviewer },
    props: ["MakeMoneyInfoDrawerVisible", "closeMakeMoneyInfoDrawer", "flowId"],
    data() {
        return {
            info: {},
            resourcesHost: process.env.VUE_APP_RESOURCES_HOST,
        };
    },
    watch: {
        MakeMoneyInfoDrawerVisible(data) {
            if (data) {
                this.getInfo();
            }
        },
    },
    methods: {
        moment(time) {
            return this.$moment(time).format("YYYY-MM-DD HH:mm:ss");
        },
        preview(url) {
            this.$refs.viewer.show([
                {
                    thumbnail: process.env.VUE_APP_RESOURCES_HOST + url,
                    source: process.env.VUE_APP_RESOURCES_HOST + url,
                },
            ]);
        },
        getInfo() {
            const _this = this;
            this.$http
                .get("/banktransferaccounts/query?flowId=" + this.flowId)
                .then((res) => {
                    if (res.rescode == 200) {
                        this.info = res.data;
                    } else {
                        _this.$Notification["error"]({
                            message: "请求失败！",
                            description: res.msg,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    _this.$Notification["error"]({
                        message: "请求失败！",
                        description: res.msg,
                    });
                });
        },
        onClose() {
            this.closeMakeMoneyInfoDrawer();
        },
    },
};
</script>
<style lang="less" scoped>
.img {
    width: 100px;
    height: 50px;
    margin-right: 8px;
    cursor: pointer;
}
.ant-divider {
    margin: 0 8px;
}
</style>
