<template>
	<div class="pageContainer">
		<a-row class="openVipPage">
			<a-spin class="spinBox" :spinning="loading">
				<template v-if="vipLavelList.length == 0 && !loading">
					<a-result status="warning" title="您的账号注册所在地未设置会员价格收费标准，请联系客服：17673161640"></a-result>
				</template>
				<template v-else>
					<a-row class="stepsBox">
						<a-steps :current="current">
							<a-step v-for="item in steps" :key="item.title" :title="item.title" />
						</a-steps>
					</a-row>
					<div v-if="current == 0">
						<a-row class="vipRow">
							<a-col class="vipCol" :span="12">
								<template v-if="vipLavelList.length != 0">
									<div v-for="(item, index) in vipLavelList" :key="index" class="vipContainer">
										<div :class="selectedVipInfo.termValidity == item.termValidity ? 'vipBox selectedVip' : 'vipBox'" @click="selectedVipChange(item)">
											<div class="left">
												<div class="name">{{ item.name }}</div>
												<div>免广告智能检测、广告人工检测、合同内容审查手续费{{ item.termValidity == 1 ? 1 : item.termValidity == 2 ? 3 : item.termValidity == 3 ? 6 : item.termValidity == 4 ? 12 : '' }}个月</div>
											</div>
											<div class="right">¥{{ item.amount }}</div>
										</div>
									</div>
								</template>
							</a-col>
							<a-col class="vipCol" :span="12">
								<div class="contractBox">
									<div class="left">
										<img src="../../assets/img/contractIcon.png" class="contractIcon" />
										合同
									</div>
									<div class="right">
										<!-- <a-button shape="round" ghost @click="downloadFile">下载</a-button> -->
										<a-button shape="round" ghost @click="priviewVipContract">预览</a-button>
									</div>
								</div>
							</a-col>
						</a-row>
					</div>
					<div v-if="current == 1">
						<a-row class="payBtn flex justify-center align-center">
							<a-button shape="round" v-for="item in payTypeList" :key="item.type" :type="selectedPayType == item.type ? 'primary' : ''" @click="selectedPayTypeChange(item.type)">{{ item.title }}</a-button>
						</a-row>
						<a-row class="payInfo">
							<template v-if="selectedPayType == 1 || selectedPayType == 2">
								<a-spin :spinning="getQrcodeLoading">
									<div class="payCode flex justify-center align-center">
										<div class="qrcode flex justify-center align-center">
											<img v-if="qrcodeUrl" :src="resourcesHost + qrcodeUrl" />
											<div v-else-if="!qrcodeUrl && !getQrcodeLoading" class="getQrcodeFaild" @click="getPayQrcodeAgain">
												<div>支付二维码获取失败</div>
												<div>点击重新获取</div>
											</div>
											<div class="mask" v-if="!qrcodeUrl && !getQrcodeLoading"></div>
										</div>
										<span class="message">请用{{ selectedPayType == 1 ? '支付宝' : selectedPayType == 2 ? '微信' : '' }}扫描二维码支付{{ selectedVipInfo.amount }}元</span>
									</div>
								</a-spin>
							</template>
							<template v-if="selectedPayType == 3">
								<a-row class="makeMoneyInfoRow flex justify-center align-center">
									<div class="makeMoneyInfo">
										<a-row>
											<a-col :span="6" class="name">公司名称：</a-col>
											<a-col :span="18">长沙鸿星智能科技有限公司</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">法定代表人：</a-col>
											<a-col :span="18">胡楠</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">账户：</a-col>
											<a-col :span="18">810000203803000001</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">开户银行：</a-col>
											<a-col :span="18">长沙银行股份有限公司麓政支行</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">打款金额：</a-col>
											<a-col :span="18">{{ selectedVipInfo.amount }}元</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">打款凭证：</a-col>
											<a-col :span="18">
												<a-upload list-type="picture-card" @change="handleFileChange" name="file" :before-upload="beforeImgUpload" :file-list="imgFileList" @preview="handlePreview" :multiple="true" :action="resourcesApiHost">
													<div v-if="imgFileList.length < 1">
														<a-icon type="plus" />
														<div class="ant-upload-text">
															上传凭证
														</div>
													</div>
												</a-upload>
											</a-col>
										</a-row>
									</div>
								</a-row>
							</template>
						</a-row>
					</div>
					<div v-if="current == 2">
						<a-row class="vipRow">
							<a-col class="vipCol" :span="12">
								<a-row class="makeMoneyInfoRow flex justify-center align-center">
									<div class="makeMoneyInfo">
										<a-row>
											<a-col :span="6" class="name">合同名称：</a-col>
											<a-col :span="18">会员服务合同</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">签约用户：</a-col>
											<a-col :span="18">{{ userInfo.name }}</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">用户电话：</a-col>
											<a-col :span="18">{{ userInfo.phone }}</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">会员名称：</a-col>
											<a-col :span="18">{{ selectedVipInfo.name }}</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">合同签字文件：</a-col>
											<a-col :span="18">
												<a-upload list-type="picture-card" @change="handleFileChange" name="file" :before-upload="beforeImgUpload" :file-list="imgFileList" @preview="handlePreview" :multiple="true" :action="resourcesApiHost">
													<div v-if="imgFileList.length < 1">
														<div>
															<a-icon type="plus" />
															<div class="ant-upload-text">
																上传图片
															</div>
														</div>
													</div>
												</a-upload>
												<span class="message">*只需将签字盖章页拍照上传即可</span>
											</a-col>
										</a-row>
										<a-row class="flex align-center">
											<a-col :span="6" class="name">您的收货地址：</a-col>
											<a-col :span="18">
												<a-input v-model="address" placeholder="请填写您的收货地址"></a-input>
											</a-col>
										</a-row>
									</div>
								</a-row>
							</a-col>
							<a-col class="vipCol" :span="12">
								<a-row class="makeMoneyInfoRow flex justify-center align-center">
									<div class="makeMoneyInfo">
										<a-row>
											<a-col :span="6" class="name">合同寄回地址：</a-col>
											<a-col :span="18">湖南省长沙市岳麓区麓谷企业广场F3栋2楼星华播控</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">收件人姓名：</a-col>
											<a-col :span="18">骆文斌</a-col>
										</a-row>
										<a-row>
											<a-col :span="6" class="name">收件人电话：</a-col>
											<a-col :span="18">17673161640</a-col>
										</a-row>
									</div>
								</a-row>
							</a-col>
						</a-row>
					</div>
					<div v-if="current == 3">
						<a-result class="resultBox" title="上传成功，请及时将合同签字盖章寄往本公司，等待平台审核··">
							<template #icon>
								<img class="resultImg" src="../../assets/img/result.png" alt="" />
							</template>
							<template #extra>
								<div class="desc">
									<a-row>
										<a-col :span="6" class="name">合同寄回地址：</a-col>
										<a-col :span="18">湖南省长沙市岳麓区麓谷企业广场F3栋2楼星华播控</a-col>
									</a-row>
									<a-row>
										<a-col :span="6" class="name">收件人姓名：</a-col>
										<a-col :span="18">骆文斌</a-col>
									</a-row>
									<a-row>
										<a-col :span="6" class="name">收件人电话：</a-col>
										<a-col :span="18">17673161640</a-col>
									</a-row>
								</div>
							</template>
						</a-result>
					</div>
					<a-row class="btnBox">
						<a-button v-if="current == 1" style="margin-left: 8px" @click="prev">
							返回
						</a-button>
						<a-button v-if="current == 0 || (current == 1 && selectedPayType == 3) || current == 2" type="primary" @click="next">
							下一步
						</a-button>
						<a-button v-if="current == steps.length - 1" type="primary" @click="allOk">
							完成
						</a-button>
					</a-row>
				</template>
			</a-spin>
		</a-row>
		<Vviewer ref="viewer"></Vviewer>
	</div>
</template>
<script>
import Vviewer from '../../components/Vviewer.vue';
import { saveFileToLink } from 'web-downloadfile';
export default {
	components: { Vviewer },
	data() {
		return {
			current: 0,
			steps: [
				{
					title: '选择会员',
				},
				{
					title: '选择支付方式',
				},
				{
					title: '签约合同',
				},
				{
					title: '签约结果',
				},
			],
			userInfo: this.$store.getters.userInfo,
			resourcesHost: process.env.VUE_APP_RESOURCES_HOST,
			resourcesApiHost: process.env.VUE_APP_RESOURCES_API_HOST,
			vipLavelList: [],
			loading: true,
			selectedVipInfo: {},
			payTypeList: [
				{
					title: '支付宝',
					type: 1,
				},
				{
					title: '微信',
					type: 2,
				},
				{
					title: '银行转账',
					type: 3,
				},
			],
			selectedPayType: 1,
			qrcodeUrl: null,
			getQrcodeLoading: true,
			imgFileList: [],
			tradeId: null,
			getTradeStatusInterval: null,
			address: null,
		};
	},
	created() {
		this.getVipLevel();
	},
	beforeDestroy() {
		if (this.getTradeStatusInterval) {
			clearInterval(this.getTradeStatusInterval);
		}
	},
	methods: {
		downloadFile() {
			let url = 'https://resources.xbov.cn/img/hxznggfwpt/%E4%BC%9A%E5%91%98%E6%9C%8D%E5%8A%A1%E5%90%88%E5%90%8C.pdf';
			var xhr = new XMLHttpRequest();
			url = url.includes('https:') ? url.replace('https:', '') : url.replace('http:', ''); //资源路径动态获取请求的方式HTTPS或HTTP
			xhr.open('get', url, true);
			xhr.setRequestHeader('Content-Type', 'application/pdf');
			xhr.setRequestHeader('If-Modified-Since', '0'); //解决缓存问题,每次请求都去请求服务器获取最新数据
			xhr.responseType = 'blob';
			(xhr.onprogress = function(e) {
				//文件下载进度
				if (fn && typeof fn == 'function') {
					fn(e); //监听文件下载进度;
				}
			}),
				(xhr.onload = function() {
					if (this.status == 200) {
						//接受二进制文件流
						var blob = this.response;
						// saveFileToBlob(blob, '', 'pdf');
						if (isBlob(blob)) {
							var downloadElement = document.createElement('a');
							var href = window.URL.createObjectURL(blob); //创建下载的链接
							downloadElement.href = href;
							downloadElement.download = '会员服务合同.pdf'; //下载后文件名
							document.body.appendChild(downloadElement);
							downloadElement.click(); //点击下载
							document.body.removeChild(downloadElement); //下载完成移除元素
							window.URL.revokeObjectURL(href); //释放掉blob对象
						} else {
							console.warn('不是blob对象类型的参数,可选择saveFileToBase64(Base64对象,文件类型)或saveFileToLink(文件链接,文件名,文件类型,进度回调方法)');
						}
					}
				}),
				xhr.send();
		},
		priviewVipContract() {
			window.open('https://resources.xbov.cn/img/hxznggfwpt/%E4%BC%9A%E5%91%98%E6%9C%8D%E5%8A%A1%E5%90%88%E5%90%8C.pdf');
		},
		allOk() {
			this.$router.replace({ name: 'Home' });
		},
		getTradeStatus() {
			let params = {
				flowId: this.tradeId,
			};
			const _this = this;
			this.$http
				.post('/viporder/queryList', params)
				.then(res => {
					let currentTradePayState = res.data.dataList[0].state;
					if (currentTradePayState == 1) {
						_this.current++;
						clearInterval(_this.getTradeStatusInterval);
						_this.getUserInfo();
					}
				})
				.catch(err => {
					console.log('获取订单状态失败', err);
				});
		},
		getUserInfo() {
			const _this = this;
			_this.$http
				.get('/userconsumer/query?userCode=' + _this.userInfo.userCode)
				.then(res => {
					if (res.rescode == 200) {
						_this.$store.dispatch('saveUserInfo', res.data);
						_this.$message.success('会员开通成功');
					} else {
						_this.$message.console.error('会员开通失败');
					}
				})
				.catch(err => {
					_this.spanning = false;
				});
		},
		getPayQrcodeAgain() {
			this.getQrcodeLoading = true;
			this.qrcodeUrl = null;
			this.getPayQrcode();
		},
		getPayQrcode() {
			if (this.selectedVipInfo.amount >= 6000) {
				return;
			} else {
				let url = '/resource/pay/alipay';
				if (this.selectedPayType == 1) {
					url = '/resource/pay/alipay';
				} else if (this.selectedPayType == 2) {
					url = '/resource/pay/qrcode';
				}
				let params = {
					vipOrderId: this.tradeId,
				};
				const _this = this;
				this.$http
					.post(url, params)
					.then(res => {
						_this.getQrcodeLoading = false;
						if (res.data.jsonHtml) {
							_this.qrcodeUrl = res.data.jsonHtml;
							if (_this.getTradeStatusInterval) {
								clearInterval(_this.getTradeStatusInterval);
							}
							_this.getTradeStatusInterval = setInterval(() => {
								_this.getTradeStatus();
							}, 1000);
						} else {
							this.qrcodeUrl = null;
						}
					})
					.catch(err => {
						this.qrcodeUrl = null;
						_this.getQrcodeLoading = false;
						console.log('获取支付二维码失败', err);
					});
			}
		},
		openVip() {
			let params = {
				userCode: this.userInfo.userCode,
				level: this.selectedVipInfo.termValidity,
			};
			const _this = this;
			_this.loading = true;
			this.$http
				.post('/viporder/openVipByUser', params)
				.then(res => {
					_this.loading = false;
					if (res.rescode == 200) {
						_this.tradeId = res.data.flowId;
						_this.getPayQrcode();
					} else {
						this.$message.warning(res.msg);
					}
				})
				.catch(err => {
					_this.loading = false;
					console.log(err);
				});
		},
		async handlePreview(file) {
			this.$refs.viewer.show([
				{
					thumbnail: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
					source: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
				},
			]);
		},
		beforeImgUpload(file) {
			return new Promise((resolve, reject) => {
				var testmsg = /^image\/(jpeg|png|jpg|bmp|gif)$/.test(file.type);
				const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
				if (!testmsg) {
					this.$message.error('仅支持图片!');
					return reject(false);
				}
				if (!isLt20M) {
					this.$message.error('上传图片大小不能超过20M!');
					return reject(false);
				}
				return resolve(true);
			});
		},
		async handleFileChange({ file, fileList }) {
			if (file.status == 'done' && file.response.rescode == 200) {
				fileList.map(item => {
					if (item.status == 'done') {
						if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != '') {
							item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
						}
					}
				});
			} else if (file.status == 'done' && file.response.rescode == 201) {
				this.$message.warning(file.response.msg);
				fileList = fileList.filter(item => {
					return item.uid != file.uid;
				});
			}
			this.imgFileList = fileList;
		},
		selectedPayTypeChange(e) {
			this.selectedPayType = e;
			this.imgFileList = [];
			if (e == 1 || e == 2) {
				this.qrcodeUrl = null;
				this.getQrcodeLoading = true;
				this.getPayQrcode();
			} else if (e == 3) {
				this.qrcodeUrl = null;
				this.getQrcodeLoading = true;
				if (this.getTradeStatusInterval) {
					clearInterval(this.getTradeStatusInterval);
				}
			}
		},
		selectedVipChange(e) {
			this.selectedVipInfo = e;
			if (this.getTradeStatusInterval) {
				clearInterval(this.getTradeStatusInterval);
			}
		},
		getVipLevel() {
			const _this = this;
			let params = {
				configKey: 'membershipLevel',
			};
			_this.$http
				.post('/systemsetting/queryList', params)
				.then(res => {
					_this.loading = false;
					let list = res.data.dataList;
					let vipLavelList = [];
					list.map(item => {
						let priceList = JSON.parse(item.configValue);
						if (priceList[0].province == _this.userInfo.province) {
							vipLavelList = priceList;
						}
					});
					_this.vipLavelList = vipLavelList;
					_this.selectedVipInfo = vipLavelList[0];
				})
				.catch(err => {
					_this.loading = false;
				});
		},
		next() {
			if (this.selectedVipInfo.amount >= 6000) {
				this.payTypeList = [
					{
						title: '银行转账',
						type: 3,
					},
				];
				this.selectedPayType = 3;
			} else {
				this.payTypeList = [
					{
						title: '支付宝',
						type: 1,
					},
					{
						title: '微信',
						type: 2,
					},
					{
						title: '银行转账',
						type: 3,
					},
				];
			}
			if (this.current == 0) {
				this.qrcodeUrl = null;
				this.getQrcodeLoading = true;
				this.openVip();
				this.current++;
			} else if (this.current == 1) {
				this.banktransferaccountsAdd();
			} else if (this.current == 2) {
				this.contractsignAdd();
			}
		},
		contractsignAdd() {
			const _this = this;
			if (this.imgFileList.length == 0) {
				this.$message.warning('请上传合同签字图片');
				return;
			} else {
				let uploadFaild = false;
				this.imgFileList.map(item => {
					if (item.status == 'uploading') {
						uploadFaild = 1; //正在上传
					} else if (item.status == 'error') {
						uploadFaild = 2; //上传失败
					}
				});
				if (uploadFaild == 1) {
					this.$message.warning('图片正在上传中，请等待图片上传完毕！');
					return;
				}
				if (uploadFaild == 2) {
					this.$message.warning('图片上传失败，请重新上传！');
					return;
				}
				if (!this.address || this.address == '') {
					this.$message.warning('请填写您的收货地址！');
					return;
				}
				let params = {
					address: this.address,
					contractSignGrade: Number(this.selectedVipInfo.termValidity),
					contractSignMoney: Number(this.selectedVipInfo.amount),
					contractSignName: '会员服务合同',
					contractSignState: 0,
					userCode: this.userInfo.userCode,
					fileUrl: this.imgFileList[0].response.data.filePath,
				};
				_this.loading = true;
				_this.$http
					.post('/contractsign/add', params)
					.then(res => {
						_this.loading = false;
						if (res.rescode == 200) {
							_this.current++;
						} else {
							_this.$message.warning(res.msg);
						}
					})
					.catch(err => {
						_this.loading = false;
					});
			}
		},
		banktransferaccountsAdd() {
			const _this = this;
			if (this.imgFileList.length == 0) {
				this.$message.warning('请上传打款凭证图片');
				return;
			} else {
				let uploadFaild = false;
				this.imgFileList.map(item => {
					if (item.status == 'uploading') {
						uploadFaild = 1; //正在上传
					} else if (item.status == 'error') {
						uploadFaild = 2; //上传失败
					}
				});
				if (uploadFaild == 1) {
					this.$message.warning('图片正在上传中，请等待图片上传完毕！');
					return;
				}
				if (uploadFaild == 2) {
					this.$message.warning('图片上传失败，请重新上传！');
					return;
				}
				let params = {
					fileUrl: this.imgFileList[0].response.data.filePath,
					money: Number(this.selectedVipInfo.amount),
					paymentWay: 1,
					vipGrade: this.selectedVipInfo.level,
					userCode: this.userInfo.userCode,
					userPhone: this.userInfo.phone,
					orderCodeId: this.tradeId,
				};
				_this.loading = true;
				_this.$http
					.post('/banktransferaccounts/add', params)
					.then(res => {
						_this.loading = false;
						if (res.rescode == 200) {
							_this.imgFileList = [];
							_this.current++;
						} else {
							_this.$message.warning(res.msg);
						}
					})
					.catch(err => {
						_this.loading = false;
					});
			}
		},
		prev() {
			this.qrcodeUrl = null;
			this.getQrcodeLoading = true;
			this.selectedPayType = 1;
			if (this.getTradeStatusInterval) {
				clearInterval(this.getTradeStatusInterval);
			}
			this.current--;
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	.openVipPage {
		background: #fff;
		padding: 16px;
		height: 100%;
		overflow-y: auto;
		.message {
			font-size: 14px;
			color: rgba(153, 153, 153, 1);
			margin-top: 12px;
		}
		.resultBox {
			.resultImg {
				width: 280px;
				height: 160px;
			}
			/deep/.ant-result-extra {
				display: flex;
				justify-content: center;
				.desc {
					border-radius: 8px;
					width: 600px;
					font-size: 16px;
					background: #eeeeee;
					padding: 20px;
					color: #999595;
					.ant-row {
						margin-bottom: 8px;
						.ant-col {
							text-align: left;
						}
						.name {
							text-align: right;
						}
					}
					.ant-row:last-child {
						margin-bottom: unset;
					}
				}
			}
		}
		.payBtn {
			.ant-btn {
				margin: 30px 50px 50px;
				width: 166px;
				height: 45px;
				background: rgba(69, 141, 243, 0.1);
				color: rgba(69, 141, 243, 1);
				border-color: rgba(69, 141, 243, 0.1);
			}
			.ant-btn-primary {
				color: #fff;
				background-color: #1890ff;
				border-color: #1890ff;
			}
		}
		.payInfo {
			.makeMoneyInfoRow {
				.makeMoneyInfo {
					width: 604px;
					box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
					border-radius: 8px;
					padding: 0 24px;
					.ant-row {
						padding: 18px 0;
						font-size: 22px;
						color: rgba(51, 51, 51, 1);
						border-bottom: 1px solid #eeeeee;
						.name {
							text-align: right;
						}
					}
					.ant-row:last-child {
						border-bottom: unset !important;
					}
				}
			}
			.payCode {
				flex-direction: column;
				.qrcode {
					position: relative;
					width: 228px;
					height: 228px;
					background: url('https://resources.xbov.cn/img/hxznggfwpt/defaultQrcode.jpg') no-repeat;
					background-size: cover;
					.mask {
						position: absolute;
						width: 100%;
						height: 100%;
						background: rgba(22, 22, 22, 0.7);
					}
					/deep/.ant-spin-container {
						font-size: 0;
					}
					.getQrcodeFaild {
						background: #fff;
						padding: 8px 16px;
						border-radius: 50px;
						color: #000;
						z-index: 100;
						cursor: pointer;
						div {
							text-align: center;
						}
					}
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.vipRow {
			.vipCol {
				padding: 0 40px;
				.makeMoneyInfoRow {
					.makeMoneyInfo {
						width: 604px;
						box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
						border-radius: 8px;
						padding: 0 24px;
						.ant-row {
							padding: 18px 0;
							font-size: 16px;
							color: rgba(51, 51, 51, 1);
							border-bottom: 1px solid #eeeeee;
							.name {
								text-align: right;
							}
						}
						.ant-row:last-child {
							border-bottom: unset !important;
						}
					}
				}
			}
		}
		.contractBox {
			display: flex;
			align-items: center;
			background-color: rgba(49, 138, 252, 0.1);
			justify-content: space-between;
			padding: 14px 25px;
			border-radius: 8px;
			width: 542px;
			.left {
				display: flex;
				align-items: center;
				color: rgba(49, 138, 252, 1);
				font-size: 18px;
				.contractIcon {
					width: 44px;
					height: 52px;
					margin-right: 18px;
				}
			}
			.right {
				.ant-btn:first-child {
					margin-right: 10px;
				}
				.ant-btn {
					color: rgba(49, 138, 252, 1);
					border-color: rgba(49, 138, 252, 1);
				}
			}
		}
		.vipContainer {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			.vipBox {
				height: 82px;
				width: 544px;
				background: url('../../assets/img/vipLavelBg.png') no-repeat;
				background-size: 100% 100%;
				margin-bottom: 12px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 25px;
				color: #725007;
				cursor: pointer;
				.left {
					div {
						font-size: 14px;
					}
					.name {
						font-size: 18px;
						font-weight: 600;
						margin-bottom: 8px;
					}
				}
				.right {
					font-size: 26px;
					font-weight: 600;
				}
			}
			.selectedVip {
				background: url('../../assets/img/vipLavelBg2.png') no-repeat;
				background-size: 100% 100%;
			}
		}
		.vipContainer:last-child {
			.vipBox {
				margin-bottom: 0;
			}
		}
		.stepsBox {
			padding: 24px 350px;
		}
		.btnBox {
			text-align: center;
			margin-top: 100px;
			.ant-btn {
				margin: 0 8px;
			}
		}
	}
}
</style>
