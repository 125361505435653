<template>
    <div class="pageContainer">
        <a-row class="mediaResourcesManagement">
            <a-form-model layout="inline" ref="ruleForm" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
                <a-form-model-item prop="type">
                    <a-select v-model="formInline.type" style="width: 150px" placeholder="请选择审核状态" allowClear>
                        <a-select-option key="0" :value="0">未审核</a-select-option>
                        <a-select-option key="1" :value="1">已通过</a-select-option>
                        <a-select-option key="2" :value="2">已拒绝</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item class="search">
                    <a-dropdown-button type="primary" html-type="submit">
                        <a-icon type="search" />查询
                        <a-menu slot="overlay">
                            <a-menu-item key="1" @click="reset">重置查询选项</a-menu-item>
                        </a-menu>
                    </a-dropdown-button>
                </a-form-model-item>
            </a-form-model>
            <a-tabs :activeKey="activeKey" @change="tabsChange">
                <a-tab-pane :key="1" tab="线下转账">
                    <!-- <a-table :row-selection="rowSelection" rowKey="tradeId" :loading="loading" :columns="columns" :data-source="data" class="table" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }"></a-table> -->
                    <a-table rowKey="flowId" :columns="bankcolumns" :data-source="data" class="table" :loading="tableLoading" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }">
                        <template slot="auditingState" slot-scope="text">
                            {{ text == 0 ? "未审核" : text == 1 ? "已通过" : text == 2 ? "已拒绝" : "--" }}
                        </template>
                        <template slot="action" slot-scope="text, record">
                            <a @click="queryDetail(record)">查看</a>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="会员合同">
                    <a-row>
                        <a-button type="primary" @click="addVipContract">新增合同</a-button>
                    </a-row>
                    <a-table rowKey="flowId" :columns="columns" :data-source="data" class="table" :loading="tableLoading" :pagination="pagination" @change="tableChange" :scroll="{ y: tableHeight }">
                        <template slot="contractSignGrade" slot-scope="text">
                            {{ text == 1 ? "一个月" : text == 2 ? "三个月" : text == 3 ? "六个月" : text == 4 ? "十二个月" : "--" }}
                        </template>
                        <template slot="contractSignState" slot-scope="text">
                            {{ text == 0 ? "未审核" : text == 1 ? "已通过" : text == 2 ? "已拒绝" : "--" }}
                        </template>
                        <template slot="action" slot-scope="text, record">
                            <a @click="queryDetail(record)">查看</a>
                        </template>
                    </a-table>
                </a-tab-pane>
            </a-tabs>
        </a-row>
        <VipContractInfoDrawer :flowId="flowId" :VipContractInfoDrawerVisible="VipContractInfoDrawerVisible" :closeVipContractInfoDrawer="closeVipContractInfoDrawer" />
        <MakeMoneyInfoDrawer :flowId="flowId" :MakeMoneyInfoDrawerVisible="MakeMoneyInfoDrawerVisible" :closeMakeMoneyInfoDrawer="closeMakeMoneyInfoDrawer" />
        <VipContractAdd :vipContractAddVisible="vipContractAddVisible" :closeVipContractAdd="closeVipContractAdd" />
    </div>
</template>
<script>
import VipContractInfoDrawer from "./components/VipContractInfoDrawer";
import MakeMoneyInfoDrawer from "./components/MakeMoneyInfoDrawer";
import VipContractAdd from "./components/VipContractAdd";
export default {
    components: { MakeMoneyInfoDrawer, VipContractInfoDrawer, VipContractAdd },
    data() {
        const _this = this;
        return {
            activeKey: 1,
            vipContractAddVisible: false,
            screenType: 1,
            screenInfo: {},
            VipContractInfoDrawerVisible: false,
            MakeMoneyInfoDrawerVisible: false,
            flowId: "",
            verifiedAddModalShow: false,
            data: [],
            tableHeight: 450,
            tableLoading: true,
            formInline: {
                type: undefined,
            },
            columns: [
                {
                    title: "合同编号",
                    dataIndex: "flowId",
                    key: "flowId",
                    ellipsis: true,
                    scopedSlots: { customRender: "flowId" },
                },
                {
                    title: "合同名称",
                    dataIndex: "contractSignName",
                    key: "contractSignName",
                    scopedSlots: { customRender: "contractSignName" },
                },
                {
                    title: "会员期限",
                    dataIndex: "contractSignGrade",
                    key: "contractSignGrade",
                    scopedSlots: { customRender: "contractSignGrade" },
                },
                {
                    title: "审核状态",
                    dataIndex: "contractSignState",
                    key: "contractSignState",
                    ellipsis: true,
                    scopedSlots: { customRender: "contractSignState" },
                },
                {
                    title: "地址",
                    dataIndex: "address",
                    key: "address",
                    scopedSlots: { customRender: "address" },
                },
                {
                    title: "操作",
                    width: 80,
                    flex: "right",
                    dataIndex: "action",
                    key: "action",
                    scopedSlots: { customRender: "action" },
                },
            ],
            bankcolumns: [
                {
                    title: "订单号",
                    dataIndex: "flowId",
                    key: "flowId",
                    ellipsis: true,
                    scopedSlots: { customRender: "flowId" },
                },
                {
                    title: "转账金额",
                    dataIndex: "money",
                    key: "money",
                    ellipsis: true,
                    scopedSlots: { customRender: "money" },
                },
                {
                    title: "审核状态",
                    dataIndex: "auditingState",
                    key: "auditingState",
                    ellipsis: true,
                    scopedSlots: { customRender: "auditingState" },
                },
                {
                    title: "提交时间",
                    dataIndex: "addTime",
                    key: "addTime",
                    ellipsis: true,
                    customRender(text) {
                        return _this.$moment(text).format("YYYY-MM-DD HH:mm:ss");
                    },
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: 80,
                    flex: "right",
                    scopedSlots: { customRender: "action" },
                },
            ],
            userInfo: {},
            mediumTypeList: [],
            examineState: null,
            userAuthenticationFail: "",
            pagination: {
                total: 0,
                current: 0,
                showTotal: (total, range) => `当前第 ${range[0]}-${range[1]} 条 共计 ${total} 条`,
                showSizeChanger: false,
            },
            pageSize: 0,
        };
    },
    created() {
        this.userInfo = this.$store.getters.userInfo;
        this.getPageList(0, 10);
        if (document.body.clientWidth <= 1366) {
            this.tableHeight = 390;
        } else if (document.body.clientWidth > 1366 && document.body.clientWidth <= 1440) {
            this.tableHeight = 500;
        } else if (document.body.clientWidth > 1440 && document.body.clientWidth <= 1600) {
            this.tableHeight = 530;
        } else if (document.body.clientWidth > 1600) {
            this.tableHeight = 650;
        }
    },
    methods: {
        closeVipContractAdd() {
            this.vipContractAddVisible = false;
            this.getPageList(0, 10);
        },
        addVipContract() {
            this.vipContractAddVisible = true;
        },
        tableChange(e) {
            this.getPageList(e.current - 1, e.pageSize);
            this.pagination.current = e.current;
        },
        tabsChange(e) {
            this.activeKey = e;
            this.getPageList(0, 10);
            this.pagination.current = 1;
            this.pageSize = 10;
            this.pagination.pageSize = 10;
        },
        closeVipContractInfoDrawer() {
            this.flowId = null;
            this.VipContractInfoDrawerVisible = false;
        },
        closeMakeMoneyInfoDrawer() {
            this.flowId = null;
            this.MakeMoneyInfoDrawerVisible = false;
        },
        queryDetail(data) {
            this.flowId = data.flowId;
            if (this.activeKey == 1) {
                this.MakeMoneyInfoDrawerVisible = true;
            } else if (this.activeKey == 2) {
                this.VipContractInfoDrawerVisible = true;
            }
        },
        closeVerifiedAddModal() {
            this.verifiedAddModalShow = false;
        },
        queryPhone() {
            this.verifiedAddModalShow = true;
        },
        getUserauthentication() {
            const _this = this;
            this.$http
                .get("/userauthentication/query?userCode=" + this.userInfo.userCode)
                .then((res) => {
                    if (res.rescode == 200) {
                        _this.examineState = res.data.examineState;
                        if (res.data.examineState == 2) {
                            _this.userAuthenticationFail = res.data.remark;
                        }
                    } else {
                        if (res.msg == "未找到") {
                            _this.examineState = 0;
                        } else {
                            _this.$Notification["error"]({
                                message: "请求失败！",
                                description: res.msg,
                            });
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    _this.$Notification["error"]({
                        message: "请求失败！",
                        description: res.msg,
                    });
                });
        },
        reset() {
            this.$refs.ruleForm.resetFields();
            this.tableLoading = true;
            this.getPageList(0, 10);
        },
        handleSubmit() {
            this.tableLoading = true;
            this.getPageList(0, 10);
        },
        getPageList(pageNum, pageSize) {
            console.log(pageNum, pageSize);
            let params = {
                userCode: this.userInfo.userCode,
                pageNum: pageNum,
                pageSize: pageSize,
            };
            let url = "/banktransferaccounts/queryPageList";
            if (this.activeKey == 2) {
                url = "/contractsign/queryPageList";
            }
            if (this.formInline.type || this.formInline.type == 0) {
                if (this.activeKey == 1) {
                    params.auditingState = this.formInline.type;
                } else if (this.activeKey == 2) {
                    params.contractSignState = this.formInline.type;
                }
            }
            const _this = this;
            this.$http
                .post(url, params)
                .then((res) => {
                    if (res.rescode == 200) {
                        let dataList = res.data.dataList;
                        this.data = dataList;
                    } else {
                        _this.$Notification["error"]({
                            message: "请求失败！",
                            description: res.msg,
                        });
                    }
                    this.tableLoading = false;
                })
                .catch((error) => {
                    this.tableLoading = false;
                    console.log(error);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.pageContainer {
    .mediaResourcesManagement {
        padding: 16px;
        height: 100%;
        background: #fff;
        .search {
            float: right;
        }
    }
}
</style>
