<template>
    <a-modal :width="1200" :footer="false" :visible="vipContractAddVisible" title="新增合同签订" @cancel="closeModal">
        <a-row class="stepsBox">
            <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
            </a-steps>
        </a-row>
        <a-row class="vipRow" v-if="current == 0">
            <a-col class="vipCol" :span="12">
                <a-row class="makeMoneyInfoRow flex justify-center align-center">
                    <div class="makeMoneyInfo">
                        <a-row>
                            <a-col :span="6" class="name">合同名称：</a-col>
                            <a-col :span="18">会员服务合同</a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">签约用户：</a-col>
                            <a-col :span="18">{{ userInfo.name }}</a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">用户电话：</a-col>
                            <a-col :span="18">{{ userInfo.phone }}</a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">会员名称：</a-col>
                            <a-col :span="18">
                                <a-select class="select" placeholder="请选择会员套餐" @change="vipLevelChange">
                                    <a-select-option v-for="item in vipLavelList" :key="item.termValidity" :value="item.termValidity">{{ item.name }}</a-select-option>
                                </a-select>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">合同签字文件：</a-col>
                            <a-col :span="18">
                                <a-upload list-type="picture-card" @change="handleFileChange" name="file" :before-upload="beforeImgUpload" :file-list="imgFileList" @preview="handlePreview" :multiple="true" :action="resourcesApiHost">
                                    <div v-if="imgFileList.length < 1">
                                        <div>
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">
                                                上传图片
                                            </div>
                                        </div>
                                    </div>
                                </a-upload>
                                <span class="message">*只需将签字盖章页拍照上传即可</span>
                            </a-col>
                        </a-row>
                        <a-row class="flex align-center">
                            <a-col :span="6" class="name">您的收货地址：</a-col>
                            <a-col :span="18">
                                <a-input v-model="address" placeholder="请填写您的收货地址"></a-input>
                            </a-col>
                        </a-row>
                    </div>
                </a-row>
            </a-col>
            <a-col class="vipCol" :span="12">
                <a-row class="makeMoneyInfoRow flex justify-center align-center">
                    <div class="makeMoneyInfo">
                        <a-row>
                            <a-col :span="6" class="name">合同寄回地址：</a-col>
                            <a-col :span="18">湖南省长沙市岳麓区麓谷企业广场F3栋2楼星华播控</a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">收件人姓名：</a-col>
                            <a-col :span="18">骆文斌</a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">收件人电话：</a-col>
                            <a-col :span="18">17673161640</a-col>
                        </a-row>
                    </div>
                </a-row>
                <div class="contractBox">
                    <div class="left">
                        <img src="../../../assets/img/contractIcon.png" class="contractIcon" />
                        合同
                    </div>
                    <div class="right">
                        <!-- <a-button shape="round" ghost @click="downloadFile">下载</a-button> -->
                        <a-button shape="round" ghost @click="priviewVipContract">预览</a-button>
                    </div>
                </div>
            </a-col>
        </a-row>
        <div v-if="current == 1">
            <a-result class="resultBox" title="上传成功，请及时将合同签字盖章寄往本公司，等待平台审核··">
                <template #icon>
                    <img class="resultImg" src="../../../assets/img/result.png" alt="" />
                </template>
                <template #extra>
                    <div class="desc">
                        <a-row>
                            <a-col :span="6" class="name">合同寄回地址：</a-col>
                            <a-col :span="18">湖南省长沙市岳麓区麓谷企业广场F3栋2楼星华播控</a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">收件人姓名：</a-col>
                            <a-col :span="18">骆文斌</a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="6" class="name">收件人电话：</a-col>
                            <a-col :span="18">17673161640</a-col>
                        </a-row>
                    </div>
                </template>
            </a-result>
        </div>
        <a-row class="btnBox">
            <a-button v-if="current == 0" type="primary" @click="next">
                下一步
            </a-button>
            <a-button v-if="current == 1" type="primary" @click="closeModal">
                完成
            </a-button>
        </a-row>
        <Vviewer ref="viewer"></Vviewer>
    </a-modal>
</template>
<script>
import Vviewer from "../../../components/Vviewer.vue";
export default {
    components: { Vviewer },
    props: ["vipContractAddVisible", "closeVipContractAdd"],
    watch: {
        vipContractAddVisible(data) {
            if (data) {
                this.getVipLevel();
            }
        },
    },
    data() {
        return {
            userInfo: this.$store.getters.userInfo,
            vipTermValidity: null,
            current: 0,
            imgFileList: [],
            vipLavelList: [],
            resourcesApiHost: process.env.VUE_APP_RESOURCES_API_HOST,
            address: null,
            steps: [
                {
                    title: "签约合同",
                },
                {
                    title: "签约结果",
                },
            ],
        };
    },
    methods: {
        priviewVipContract() {
            window.open("https://resources.xbov.cn/img/hxznggfwpt/%E4%BC%9A%E5%91%98%E6%9C%8D%E5%8A%A1%E5%90%88%E5%90%8C.pdf");
        },
        contractsignAdd() {
            const _this = this;
            if (!this.vipTermValidity || this.vipTermValidity == "") {
                this.$message.warning("请选择会员套餐！");
                return;
            }
            if (this.imgFileList.length == 0) {
                this.$message.warning("请上传合同签字图片");
                return;
            }
            let uploadFaild = false;
            this.imgFileList.map((item) => {
                if (item.status == "uploading") {
                    uploadFaild = 1; //正在上传
                } else if (item.status == "error") {
                    uploadFaild = 2; //上传失败
                }
            });
            if (uploadFaild == 1) {
                this.$message.warning("图片正在上传中，请等待图片上传完毕！");
                return;
            }
            if (uploadFaild == 2) {
                this.$message.warning("图片上传失败，请重新上传！");
                return;
            }
            if (!this.address || this.address == "") {
                this.$message.warning("请填写您的收货地址！");
                return;
            }
            let vipLavelList = this.vipLavelList;
            let selectedVipInfo = {};
            vipLavelList.map((item) => {
                if (item.vipTermValidity == this.vipTermValidity) {
                    selectedVipInfo = item;
                }
            });
            let params = {
                address: this.address,
                contractSignGrade: Number(this.vipTermValidity),
                contractSignMoney: Number(selectedVipInfo.amount),
                contractSignName: "会员服务合同",
                contractSignState: 0,
                userCode: this.userInfo.userCode,
                fileUrl: this.imgFileList[0].response.data.filePath,
            };
            _this.loading = true;
            _this.$http
                .post("/contractsign/add", params)
                .then((res) => {
                    _this.loading = false;
                    if (res.rescode == 200) {
                        _this.current++;
                    } else {
                        _this.$message.warning(res.msg);
                    }
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        next() {
            this.contractsignAdd();
        },
        vipLevelChange(e) {
            this.vipTermValidity = e;
        },
        getVipLevel() {
            const _this = this;
            let params = {
                configKey: "membershipLevel",
            };
            _this.$http
                .post("/systemsetting/queryList", params)
                .then((res) => {
                    _this.loading = false;
                    let list = res.data.dataList;
                    let vipLavelList = [];
                    list.map((item) => {
                        let priceList = JSON.parse(item.configValue);
                        if (priceList[0].province == _this.userInfo.province) {
                            vipLavelList = priceList;
                        }
                    });
                    console.log(vipLavelList);
                    _this.vipLavelList = vipLavelList;
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        async handlePreview(file) {
            this.$refs.viewer.show([
                {
                    thumbnail: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
                    source: process.env.VUE_APP_RESOURCES_HOST + file.response.data.filePath,
                },
            ]);
        },
        beforeImgUpload(file) {
            return new Promise((resolve, reject) => {
                var testmsg = /^image\/(jpeg|png|jpg|bmp|gif)$/.test(file.type);
                const isLt20M = file.size / 1024 / 1024 <= 20; //图片大小不超过2MB
                if (!testmsg) {
                    this.$message.error("仅支持图片!");
                    return reject(false);
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过20M!");
                    return reject(false);
                }
                return resolve(true);
            });
        },
        async handleFileChange({ file, fileList }) {
            if (file.status == "done" && file.response.rescode == 200) {
                fileList.map((item) => {
                    if (item.status == "done") {
                        if (item.response.data.coverImgUrl && item.response.data.coverImgUrl != "") {
                            item.thumbUrl = process.env.VUE_APP_RESOURCES_HOST + item.response.data.coverImgUrl;
                        }
                    }
                });
            } else if (file.status == "done" && file.response.rescode == 201) {
                this.$message.warning(file.response.msg);
                fileList = fileList.filter((item) => {
                    return item.uid != file.uid;
                });
            }
            this.imgFileList = fileList;
        },
        closeModal() {
            this.closeVipContractAdd();
        },
    },
};
</script>
<style lang="less" scoped>
.contractBox {
    display: flex;
    align-items: center;
    background-color: rgba(49, 138, 252, 0.1);
    justify-content: space-between;
    padding: 14px 25px;
    border-radius: 8px;
    width: 542px;
    margin-top: 20px;
    .left {
        display: flex;
        align-items: center;
        color: rgba(49, 138, 252, 1);
        font-size: 18px;
        .contractIcon {
            width: 44px;
            height: 52px;
            margin-right: 18px;
        }
    }
    .right {
        .ant-btn:first-child {
            margin-right: 10px;
        }
        .ant-btn {
            color: rgba(49, 138, 252, 1);
            border-color: rgba(49, 138, 252, 1);
        }
    }
}
.btnBox {
    text-align: center;
    margin-top: 100px;
    .ant-btn {
        margin: 0 8px;
    }
}
.resultBox {
    .resultImg {
        width: 280px;
        height: 160px;
    }
    /deep/.ant-result-extra {
        display: flex;
        justify-content: center;
        .desc {
            border-radius: 8px;
            width: 600px;
            font-size: 16px;
            background: #eeeeee;
            padding: 20px;
            color: #999595;
            .ant-row {
                margin-bottom: 8px;
                .ant-col {
                    text-align: left;
                }
                .name {
                    text-align: right;
                }
            }
            .ant-row:last-child {
                margin-bottom: unset;
            }
        }
    }
}
.select {
    width: 150px;
}
.stepsBox {
    padding: 0 300px 30px;
}
.vipRow {
    .vipCol {
        padding: 0 20px;
        .makeMoneyInfoRow {
            .makeMoneyInfo {
                width: 604px;
                box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                padding: 0 24px;
                .ant-row {
                    padding: 18px 0;
                    font-size: 16px;
                    color: rgba(51, 51, 51, 1);
                    border-bottom: 1px solid #eeeeee;
                    .name {
                        text-align: right;
                    }
                }
                .ant-row:last-child {
                    border-bottom: unset !important;
                }
            }
        }
    }
}
</style>
